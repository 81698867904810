import React from 'react';
import "./PagesStyle.css";
 function AboutUs (){
    return (
        <>
            <h1 style={{textAlign:'center',color:'white',backgroundColor:' rgb(67, 36, 150)',padding:'10rem',padding:'10rem',margin:"0px",fontSize:"5rem"}}>About Us</h1>
            <div className='about_us'><h2 >ABOUT US</h2>
            <p>Brilliant Hi Tech Solution embraces synergistic partnerships and cooperation, where parties work together to achieve shared goals and maximize 
                their collective strengths. This collaborative approach fosters mutual benefits and enhances overall success.</p>
            </div>
        </>
    )
 }
 export default AboutUs;