import co3 from "./asset/co3.jpg"
import co2 from "./asset/co2.jpg"
import co4 from "./asset/co4.jpg"
import co5 from "./asset/co5.jpg"
export const sliderData = [
  {
    image: co2,
    heading: "Your trusted network partner ! ",
  },
  {
    image: co3,
    heading: "Cutting-edge Networking solution!",
  },
  {
    image: co4,
    heading: "Reliable and Secure performance!",
  },
   {
    image: co5,
    heading: "Enhance your Digital connectivity with us!",
  },
];