import React from "react";
import SvgAnimation from "../components/SvgAnimation";

function Products() {
  return (
    <div>
      <h1 style={{textAlign:'center',color:'white',backgroundColor:' rgb(67, 36, 150)',padding:'10rem',margin:"0px",fontSize:"5rem"}}>Produacts</h1>
      <SvgAnimation/>
    </div>
  );
}

export default Products;